import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { io } from "socket.io-client"; // Importing socket.io-client
import { Tab, Nav } from "react-bootstrap";

// Your other imports...

const Chat = (props) => {
  const [messageList, setMessageList] = useState(false);
  const [currentMessageData, setCurrentMessageData] = useState({
    messages: [],
  });
  const [newMessage, setNewMessage] = useState("");
  const [myAccount, setMyAccount] = useState({});
  const [batchmates, setBatchmates] = useState([]);
  const [loading, setLoading] = useState(true);

  // Step 1: Create a ref for the chat container
  const chatContainerRef = useRef(null);

  const ws = useRef(null);

  function getHumanReadableDate(timestamp) {
    const now = new Date();
    const messageDate = new Date(timestamp);
    const diff = (now - messageDate) / 1000; // Difference in seconds

    let humanReadable = "";

    if (diff < 60) {
      humanReadable = "a few seconds ago";
    } else if (diff < 3600) {
      const minutes = Math.floor(diff / 60);
      humanReadable = `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (diff < 86400) {
      const hours = Math.floor(diff / 3600);
      humanReadable = `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(diff / 86400);
      humanReadable = `${days} day${days !== 1 ? "s" : ""} ago`;
    }

    return humanReadable;
  }

  // Handle incoming WebSocket messages for both users
  useEffect(() => {
    if (
      !myAccount ||
      !myAccount.user_app_id ||
      !myAccount.track ||
      !myAccount.batch
    ) {
      console.warn("Missing required fields:", myAccount);
      return;
    }

    // WebSocket connection
    const url = `wss://orddnkh4ej.execute-api.ap-south-1.amazonaws.com/dev?author=${encodeURIComponent(
      myAccount.user_app_id
    )}&track=${encodeURIComponent(myAccount.track)}&batch=${encodeURIComponent(
      myAccount.batch
    )}`;

    ws.current = new WebSocket(url);

    ws.current.onopen = () => {
      console.log("WebSocket connected");
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      const timestamp = getHumanReadableDate(message.timestamp);

      // Check if the received message is for this user
      if (
        message.recipient === myAccount.user_app_id ||
        message.sender === myAccount.user_app_id
      ) {
        setCurrentMessageData((prevData) => ({
          ...prevData,
          messages: [...(prevData?.messages || []), { ...message, timestamp }],
        }));
      }
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.current.onclose = () => {
      console.log("WebSocket disconnected");
    };

    return () => {
      if (ws.current) {
        ws.current.close();
        console.log("WebSocket connection closed");
      }
    };
  }, [myAccount]);

  // Send message and immediately update state for both users
  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;

    const newMsg = {
      action: "sendMessage",
      sender: myAccount.user_app_id,
      recipient: currentMessageData.user_app_id,
      batch: myAccount.batch,
      track: myAccount.track,
      message: newMessage,
      timestamp: getHumanReadableDate(new Date().toISOString()),
    };

    // Check if WebSocket is open before sending the message
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(newMsg)); // Send the message as a string
    } else {
      console.warn("WebSocket connection is not open.");
    }

    // Update the local state for both users immediately after sending
    setCurrentMessageData((prevData) => ({
      ...prevData,
      messages: [...(prevData?.messages || []), newMsg],
    }));

    setNewMessage(""); // Clear the input field
  };

  // Step 3: Auto-scroll to the bottom when messages change
  useEffect(() => {
    const scrollToBottom = () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    };

    // Using setTimeout to allow for DOM update before scrolling
    setTimeout(scrollToBottom, 100);
  }, [currentMessageData.messages]);

  // Fetch batchmates data (You already have this part)
  useEffect(() => {
    const fetchBatchmates = async () => {
      setLoading(true);

      try {
        const response = await fetch(
          "https://afpgnccpjj.execute-api.ap-south-1.amazonaws.com/dev/api/",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              method: "getchatsdata",
              web_auth_token_id: props.reduxCurrentState.one.webAuthTokenId,
            }),
          }
        );

        const responseJson = await response.json();

        if (!responseJson["error"]) {
          setBatchmates(responseJson["batchmates"]);
          setMyAccount(responseJson["my_account"]);
        } else {
          throw new Error(responseJson["message"]);
        }
      } catch (error) {
        console.log("Request failed", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBatchmates();
  }, [props.reduxCurrentState.one.webAuthTokenId]);

  return (
    <>
      <div className="row">
        <div className="col-xl-4 chat-left-area">
          {/* Left Chat Area (Sidebar) */}
          <PerfectScrollbar className="dlab-scroll card chat-sidebar">
            <div className="card-body">
              <div className="message-box d-flex align-items-center">
                <img src={myAccount?.pfp || "Loading..."} alt="" />
                <div className="ms-3">
                  <h3 className="mb-0">{myAccount?.name || "Loading..."}</h3>
                  <span>Roll No : {myAccount?.roll_no || "Loading..."}</span>
                </div>
              </div>

              <div className="chat-tabs">
                <Tab.Container defaultActiveKey="Private">
                  <div className="course-details-tab style-2">
                    <nav>
                      <Nav as="div" className="nav nav-tabs">
                        <Nav.Link
                          as="button"
                          eventKey="Group"
                          className="nav-link"
                        >
                          My Chats
                        </Nav.Link>
                        <Nav.Link
                          as="button"
                          eventKey="Private"
                          className="nav-link"
                        >
                          Batchmates
                        </Nav.Link>
                      </Nav>
                    </nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="Private">
                        <ul>
                          {batchmates.map((item, ind) => (
                            <li
                              className="chat-bx"
                              onClick={() => setCurrentMessageData(item)}
                              key={ind}
                            >
                              <div className="chat-img">
                                <img src={item.pfp} alt="" />
                              </div>
                              <div className="mid-info">
                                <h4 className="name">{item.name}</h4>
                                <span>Roll No : {item.roll_no}</span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Group">
                        <ul>
                          {batchmates.map((item, i) => (
                            <li className="chat-bx" key={i}>
                              <div className="chat-img">
                                <img src={item.image} alt="" />
                              </div>
                              <div className="mid-info">
                                <h4 className="name">{item.title}</h4>
                                <span>{item.text}</span>{" "}
                              </div>
                              <div className="right-info">
                                <p>12:45 PM</p>
                                <span className="badge badge-primary">2</span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </div>
            </div>
          </PerfectScrollbar>
        </div>

        <div className="col-xl-8 chat-mid-area">
          <div className="message-box style-1 d-flex align-items-center">
            <img src={currentMessageData?.pfp || "Loading..."} alt="" />
            <div className="ms-3 flex-1">
              <h4>{currentMessageData?.name || "Loading..."}</h4>
              <span>
                <svg
                  className="me-1"
                  width="8"
                  height="9"
                  viewBox="0 0 8 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="4" cy="4.5" r="4" fill="#4CBC9A" />
                </svg>
                Available
              </span>
            </div>
          </div>

          <div
            ref={chatContainerRef}
            style={{
              maxHeight: "400px", // Adjust height as needed
              overflowY: "scroll", // Enables scrolling
              scrollbarWidth: "thin", // For Firefox
              scrollbarColor: "transparent transparent", // For Firefox
            }}
            className="chat-box-area dlab-scroll"
            ref={chatContainerRef}
            options={{ suppressScrollX: true }}
          >
            {currentMessageData?.messages?.length > 0 ? (
              currentMessageData.messages.map((msg, index) => (
                <div
                  className={`media my-4 ${
                    msg.sender === myAccount.user_app_id
                      ? "justify-content-end align-items-end"
                      : "justify-content-start align-items-start"
                  }`}
                  key={msg.id}
                >
                  <div
                    className={
                      msg.sender === myAccount.user_app_id
                        ? "message-sent w-auto"
                        : "message-received w-auto"
                    }
                  >
                    <p className="mb-1">{msg.message}</p>
                    <span className="fs-12">{msg.timestamp}</span>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-muted text-center mt-5">
                No messages to display
              </p>
            )}
          </div>

          <div className="type-massage">
            <div className="input-group">
              <textarea
                className="form-control"
                placeholder="Type message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              ></textarea>
              <div className="input-group-append">
                <button
                  type="button"
                  className="btn btn-secondary rounded text-white"
                  onClick={handleSendMessage}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.58106 13.6267L10.5819 12.2265C11.2071 12.1016 11.2071 11.8984 10.5819 11.7735L3.58106 10.3732C3.16406 10.29 2.75793 9.88348 2.67468 9.46686L1.27443 2.46599C1.14918 1.84049 1.51781 1.54986 2.09718 1.81724L23.4606 11.6771C23.8464 11.8552 23.8464 12.1447 23.4606 12.3229L2.09718 22.1827C1.51781 22.4501 1.14918 22.1595 1.27443 21.534L2.67468 14.5331C2.75793 14.1165 3.16406 13.71 3.58106 13.6267Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(Chat);
