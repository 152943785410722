const initialState = {
  webAuthTokenId: "",
  errorMessage: "",
  successMessage: "",
  showLoading: false,
  role: "Student",
  profileComplete: false,

  darkMode: true,

  leads: [],

  chartData: [],

  chartDataByTime: {
    "All Days": [],
    "Last 7 Days": [],
    "Last 14 Days": [],
    "Last 30 Days": [],
    "Last 45 Days": [],
    "Last 60 Days": [],
    "Last 90 Days": [],
  },

  leadCountsByTime: {
    "Last 14 Days": { days: [], counts: [] },
    "Last 30 Days": { days: [], counts: [] },
    "Last 45 Days": { days: [], counts: [] },
    "Last 60 Days": { days: [], counts: [] },
    "Last 90 Days": { days: [], counts: [] },
  },

  leadSourceChartData: [],

  leadSourceChartDataByDomain: {
    All: [],
    "Data Analytics": [],
    "Data Science & AI": [],
    "Generative AI": [],
    "Digital Marketing": [],
    "Cyber Security": [],
    "Blockchain & Crypto": [],
  },

  leadStats: {
    total_leads: 0,
    avg_leads_per_week: 0,
    lead_difference_14_days: 0,
    growth_status : 0
  },

  leadCounts: { days: [], counts: [] },

  // currentLeadComments : [{comment: 'Hi', created_at: 'a few seconds ago'}, {comment: 'this is s a tes\n\nhihi\n\nhihi\nhihi\n', created_at: '3 hours ago'}],
  currentLeadComments: [],

  myAccount: {
    username: "",
    fullname: "",
    email: "",
    batch: "",
    batch_name: "",
    rollno: "",
    image:
      "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/pfps/M.png",
    upi_id: null,
  },

  myprofile: {
    name: "metamady.eth",
    joined: "July, 23",
    photoUrl: "",
    attendence: { total: 10, offline: 7, online: 3 },
    coins: { total: 100 },
  },
};

export function OMSReducer(state = initialState, action) {
  if (action.type === "GET_LEADS") {
    return {
      ...state,
      leads: action.payload.leads,
      chartData: action.payload.chart_data,
      leadSourceChartData: action.payload.lead_source_chart_data,
      leadCounts: action.payload.lead_counts,
      chartDataByTime: action.payload.chart_data_by_time,
      leadCountsByTime: action.payload.lead_counts_by_time,
      leadSourceChartDataByDomain: action.payload.lead_source_chart_data1,
      leadStats: action.payload.lead_stats
    };
  }

  if (action.type === "GET_LEAD_COMMENTS_SUCCESS") {
    return {
      ...state,
      currentLeadComments: action.payload.lead_comments,
    };
  }
  if (action.type === "ADD_COMMENT_SUCCESS") {
    return {
      ...state,
      currentLeadComments: action.payload.lead_comments,
    };
  }

  return state;
}
