import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

// import saveAndSubmitLeadAction from "../../../store/actions/OMS/Actions";

import { saveAndSubmitLeadAction } from "../../../store/actions/OMS/Actions";

import { ThemeContext } from "../../../context/ThemeContext";

const NewEnquiry = (props) => {
  const [data, setData] = useState(
    document.querySelectorAll(".email-right-box .email-list .message")
  );
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [image, setImage] = useState(
    props.reduxCurrentState.one.myAccount.image
  );

  const reduxUsername = useSelector((state) => state.one.myAccount.username);
  const [isEditUsername, setIsEditUsername] = useState(false);
  const [localUsername, setLocalUsername] = useState(reduxUsername);

  const [isLoading, setIsLoading] = useState(false);

  const [fullName, setFullname] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [gender, setGender] = useState("Male");

  const [state, setState] = useState("Education Completed");

  const [yop, setYop] = useState("2024");
  const [mainDegree, setMainDegree] = useState("B.Tech");
  const [specialization, setSpecialization] = useState("Computer Science");

  const [technologyInterested, setTechnologyInterested] = useState(
    "Data Science & AI"
  );
  const [currentKnowledge, setCurrentKnowledge] = useState("LKG");
  const [learningMode, setLearningMode] = useState("Offline");
  const [learningGoal, setLearningGoal] = useState("Getting a Job");
  const [leadSource, setLeadSource] = useState("Unknown");
  const [nextAction, setNextAction] = useState("Need to Send Content");

  const [upiID, setUpiID] = useState(
    props.reduxCurrentState.one.myAccount.upi_id
  );

  const [isEdited, setIsEdited] = useState(false);

  const dispatch = useDispatch();

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
    changeNavigationHader(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
  }, [darkModeData]);

  useEffect(() => {
    // dispatch(getMyAccountDetails(props.reduxCurrentState.one.webAuthTokenId));
    setLocalUsername(reduxUsername);
  }, [reduxUsername]);

  const handleFullnameChange = (e) => {
    setFullname(e.target.value);
  };

  const handleWhatsappNoChange = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/[^0-9]/g, "");

    if (numericValue.length <= 10) {
      setWhatsappNo(numericValue);
    }
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleGender = (event) => {
    setGender(event.target.value);
  };

  const handleState = (event) => {
    setState(event.target.value);
  };

  const handleYOP = (event) => {
    setYop(event.target.value);
  };

  const handleMainDegree = (event) => {
    setMainDegree(event.target.value);
  };

  const handleSpecialization = (event) => {
    setSpecialization(event.target.value);
  };

  const handleTechnologyInterested = (event) => {
    setTechnologyInterested(event.target.value);
  };

  const handleCurrentKnowledge = (event) => {
    setCurrentKnowledge(event.target.value);
  };

  const handleLearningMode = (event) => {
    setLearningMode(event.target.value);
  };

  const handleLearningGoal = (event) => {
    setLearningGoal(event.target.value);
  };

  const handleLeadSource = (event) => {
    setLeadSource(event.target.value);
  };

  const handleNextAction = (event) => {
    setNextAction(event.target.value);
  };

  const history = useHistory();
  const onPressCancel = (temp) => {
    history.push("/dashboard");
  }

  const onPressSaveAndSubmit = (temp) => {
    let message = null;

    setIsLoading(true);

    if (!fullName) {
      message = "Please enter valid name";
    } else if (!whatsappNo | (whatsappNo.length != 10)) {
      message = "Please enter valid whatsapp number";
    }

    if (message !== null) {
      swal("Oops", message, "error");
      setIsLoading(false); // Stop loading if there's an error
      return;
    }

    const data = {
      fullName: fullName,
      whatsappNo: whatsappNo,
      technologyInterested: technologyInterested,
      leadSource: leadSource
    };

    dispatch(
      saveAndSubmitLeadAction(props.reduxCurrentState.one.webAuthTokenId, data)
    )
      .then(() => {
        setIsLoading(false);
        history.push("/dashboard");
      })
      .catch((error) => {
        swal("Error", "Something went wrong. Please try again later.", "error");
        setIsLoading(false);
      });
  };

  return (
    <Fragment>
      <div className="row">
        <div className="login-wrapper ">
          <div className="container h-100 ">
            <div className="row h-100 d-flex justify-content-center align-items-center">
              <div className="col-xl-0 col-md-8">
                <div className="sign-in-your">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row">
                        <div style={{ textAlign: "center" }}>
                          <label
                            style={{ fontSize: "15px", fontWeight: "bold" }}
                          >
                            Basic Details
                          </label>
                          <hr
                            style={{
                              border: "0.5px solid black",
                              width: "100%",
                            }}
                          />
                        </div>

                        <div>
                          <br></br>
                        </div>

                        <div className="form-group mb-4 col-md-6">
                          <label>
                            Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={fullName}
                            placeholder="Please enter name"
                            onChange={handleFullnameChange}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                          />
                        </div>

                        <div className="form-group mb-4 col-md-6">
                          <label>
                            Whatsapp No <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={whatsappNo}
                            onChange={handleWhatsappNoChange}
                            placeholder="Please enter whatsapp no"
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                            length={10}
                          />
                        </div>
                        

                        <div className="form-group mb-4 col-md-6">
                          <label>Technology Interested</label>
                          <select
                            id="inputState"
                            className="form-control"
                            value={technologyInterested}
                            onChange={handleTechnologyInterested}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                          >
                            <option value="Data Science & AI">
                              Data Science & AI
                            </option>
                            <option value="Data Analytics">
                              Data Analytics
                            </option>
                            <option value="Generative AI">Generative AI</option>
                            <option value="Digital Marketing">
                              Digital Marketing
                            </option>
                            <option value="Cyber Security">
                              Cyber Security
                            </option>
                            <option value="Blockchain & Crypto">
                              Blockchain & Crypto
                            </option>
                            <option value="Others">
                              Others
                            </option>

                            
                          </select>
                        </div>

                        <div className="form-group mb-4 col-md-6">
                          <label>Lead Source</label>
                          <select
                            id="specialization"
                            className="form-control"
                            value={leadSource}
                            onChange={handleLeadSource}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                          >
                            <option value="Outside Board">
                              Outside Banners
                            </option>
                            <option value="Direct Walkin">Direct Walkin</option>
                            <option value="Hostel Poster">Hostel Poster</option>
                            <option value="Social Media">Social Media</option>
                            <option value="Internet">Internet</option>
                            <option value="Referral">Referral</option>
                            <option value="Unknown">Unknown</option>
                          </select>
                          
                        </div>
                      
                      </div>

                      {isLoading ? (
                        <div className="text-center">
                          <div
                            className="spinner-border text-primary text-center"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="form-group mb-3 col-md-6">
                            <label>.</label>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-primary btn-block"
                                onClick={() => onPressCancel(true)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>

                          <div className="form-group mb-3 col-md-6">
                            <label>.</label>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-secondary btn-block"
                                onClick={() => onPressSaveAndSubmit(false)}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(NewEnquiry);
