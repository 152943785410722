import React, { useState, useEffect } from "react";
import { Dropdown, Badge } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";

import { Row, Col, Card, Tab, Nav } from "react-bootstrap";

import { connect, useDispatch, useSelector } from "react-redux";

import LogoutPage from "./Logout";

import { viewNotifications } from "../../../store/actions/AuthActions";

import { toggleDarkMode } from "../../../store/actions/AuthActions";

import avatar1 from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import avatar3 from "../../../images/avatar/3.jpg";
import avatar4 from "../../../images/avatar/4.jpg";

import Notes from "../../pages/components/chatBox/Notes";
import Alerts from "../../pages/components/chatBox/Alerts";
import Chat from "../../pages/components/chatBox/Chat";

const Header = (props, { onNote }) => {
  const dispatch = useDispatch();

  const [rightSelect, setRightSelect] = useState("Eng");
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isNotificationClicked, setIsNotificationClicked] = useState(false);

  const [activeTabEda, setActiveTabEda] = useState("Batch 1");

  const [toggleTab, settoggleTab] = useState(
    window.location.hash.slice(1) ? window.location.hash.slice(1) : "chat"
  );

  const darkMode = useSelector((state) => state.one.darkMode);

  const notificationCount =
    props.reduxCurrentState.one.unReadNotifications.length;

  // const notificationCount = 3;

  const iconColor = isDarkMode ? "white" : "black";
  const backgroundColor = isDarkMode ? "black" : "white";

  const dataToggle = [
    { href: "#notes", name: "Notes" },
    { href: "#alerts", name: "Alerts" },
    { href: "#chat", name: "Chat" },
  ];

  const handleDarkModeToggle = () => {
    dispatch(toggleDarkMode(!darkMode));
  };

  const onClickNotification = () => {
    if (isNotificationClicked) {
      return;
    } else {
      setIsNotificationClicked(true);

      if (notificationCount > 0) {
        const notificationId =
          props.reduxCurrentState.one.unReadNotifications[0].uuid;
        dispatch(
          viewNotifications(
            props.reduxCurrentState.one.webAuthTokenId,
            notificationId
          )
        );
      }
    }
  };

  //For fix header
  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  const toggle = "chatbox";

  //const [searchBut, setSearchBut] = useState(false);
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName;

  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              {finalName.join(" ").length === 0 && (
                <div>
                  <div className="dashboard_bar">
                    👋 {props.reduxCurrentState.one.myAccount.first_name}
                  </div>
                </div>
              )}
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.join(" ").length === 0
                  ? ""
                  : finalName.join(" ") === "dashboard dark"
                  ? ""
                  : finalName.join(" ")}
              </div>
            </div>

            
            
            <div className="navbar-nav header-right">
              <div className="nav-item d-flex align-items-center">
                <div style={{}}>
                  <i
                    className={
                      darkMode
                        ? "bi bi-lightbulb-fill"
                        : "bi bi-moon-stars-fill"
                    }
                    style={{
                      fontSize: "3em",
                      color: darkMode ? "white" : "#343a40",
                      cursor: "pointer",
                    }}
                    onClick={handleDarkModeToggle}
                  ></i>
                </div>
              </div>

              
              <div className="dlab-side-menu">
                <div className="sidebar-social-link ">
                  <ul className="">

                  {/* <Dropdown
                      as="li"
                      className="nav-item dropdown notification_dropdown "
                    >
                      <Dropdown.Toggle
                        variant=""
                        as="a"
                        className="nav-link  i-false c-pointer"
                        to="/chat"
                      >
                        <Link to="/chat">
                        <i class="bi bi-chat-dots-fill"></i>
                        </Link>
                      </Dropdown.Toggle>
                    </Dropdown> */}

                    {/* <Dropdown
                      as="li"
                      className="nav-item dropdown notification_dropdown border-0 pb-0"
                    >
                      <Dropdown.Toggle
                        className="nav-link i-false c-pointer"
                        variant=""
                        as="a"
                      >
                        <div onClick={() => onClickNotification()}>
                          <i class="bi bi-people-fill"></i>

                          {isNotificationClicked == false &&
                            notificationCount > 0 && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "-5px", // Adjust top position as needed
                                  right: "-5px", // Adjust right position as needed
                                  background: "#ff3c26", // Adjust background color as needed
                                  color: "white", // Adjust text color as needed
                                  borderRadius: "50%",
                                  width: "20px",
                                  height: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "12px",
                                }}
                              >
                                {notificationCount}
                              </div>
                            )}
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        align="right"
                        className="mt-2 dropdown-menu dropdown-menu-end "
                      >
                        <PerfectScrollbar
                          style={{ height: "370px" }}
                          id="DZ_W_Todo3"
                          className="widget-media dlab-scroll p-3 height380"
                        >
                          <ul className="timeline">
                            <li>
                              <div className="timeline-panel">
                                <div className="media me-2">
                                  <img alt="" width="50" src={avatar1} />
                                </div>
                                <div className="media-body">
                                  <h5 className="mb-1">
                                    Alfie Mason{" "}
                                    <small className="text-muted">
                                      29 July 2022
                                    </small>
                                  </h5>
                                  <p className="mb-1">
                                    I shared this on my fb wall a few months
                                    back..
                                  </p>
                                  <Link
                                    to="/widget-basic"
                                    className="btn btn-primary btn-xxs shadow"
                                  >
                                    Accept
                                  </Link>
                                  <Link
                                    to="/widget-basic"
                                    className="btn btn-outline-danger btn-xxs ms-1"
                                  >
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="timeline-panel">
                                <div className="media me-2 media-info">KG</div>
                                <div className="media-body">
                                  <h5 className="mb-1">
                                    Jacob Tucker{" "}
                                    <small className="text-muted">
                                      29 July 2022
                                    </small>
                                  </h5>
                                  <p className="mb-1">
                                    I shared this on my fb wall a few months
                                    back..
                                  </p>
                                  <Link
                                    to="/widget-basic"
                                    className="btn btn-primary btn-xxs shadow"
                                  >
                                    Accept
                                  </Link>
                                  <Link
                                    to="/widget-basic"
                                    className="btn btn-outline-danger btn-xxs ms-1"
                                  >
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="timeline-panel">
                                <div className="media me-2 media-success">
                                  <img alt="" width="50" src={avatar2} />
                                </div>
                                <div className="media-body">
                                  <h5 className="mb-1">
                                    Jack Ronan{" "}
                                    <small className="text-muted">
                                      29 July 2022
                                    </small>
                                  </h5>
                                  <p className="mb-1">
                                    I shared this on my fb wall a few months
                                    back..
                                  </p>
                                  <Link
                                    to="/widget-basic"
                                    className="btn btn-primary btn-xxs shadow"
                                  >
                                    Accept
                                  </Link>
                                  <Link
                                    to="/widget-basic"
                                    className="btn btn-outline-danger btn-xxs ms-1"
                                  >
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="timeline-panel">
                                <div className="media me-2">
                                  <img alt="" width="50" src={avatar1} />
                                </div>
                                <div className="media-body">
                                  <h5 className="mb-1">
                                    Noah Baldon{" "}
                                    <small className="text-muted">
                                      29 July 2022
                                    </small>
                                  </h5>
                                  <p className="mb-1">
                                    I shared this on my fb wall a few months
                                    back..
                                  </p>
                                  <Link
                                    to="/widget-basic"
                                    className="btn btn-primary btn-xxs shadow"
                                  >
                                    Accept
                                  </Link>
                                  <Link
                                    to="/widget-basic"
                                    className="btn btn-outline-danger btn-xxs ms-1"
                                  >
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="timeline-panel">
                                <div className="media me-2 media-danger">
                                  PU
                                </div>
                                <div className="media-body">
                                  <h5 className="mb-1">
                                    Thomas Grady{" "}
                                    <small className="text-muted">
                                      02:26 PM
                                    </small>
                                  </h5>
                                  <p className="mb-1">
                                    I shared this on my fb wall a few months
                                    back..
                                  </p>
                                  <Link
                                    to="/widget-basic"
                                    className="btn btn-primary btn-xxs shadow"
                                  >
                                    Accept
                                  </Link>
                                  <Link
                                    to="/widget-basic"
                                    className="btn btn-outline-danger btn-xxs ms-1"
                                  >
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="timeline-panel">
                                <div className="media me-2 media-primary">
                                  <img alt="" width="50" src={avatar3} />
                                </div>
                                <div className="media-body">
                                  <h5 className="mb-1">
                                    Oscar Weston{" "}
                                    <small className="text-muted">
                                      29 July 2022
                                    </small>
                                  </h5>
                                  <p className="mb-1">
                                    I shared this on my fb wall a few months
                                    back..
                                  </p>
                                  <Link
                                    to="/widget-basic"
                                    className="btn btn-primary btn-xxs shadow"
                                  >
                                    Accept
                                  </Link>
                                  <Link
                                    to="/widget-basic"
                                    className="btn btn-outline-danger btn-xxs ms-1"
                                  >
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </PerfectScrollbar>
                      </Dropdown.Menu>
                    </Dropdown> */}

                    <Dropdown
                      as="li"
                      className="nav-item dropdown notification_dropdown"
                    >
                      <Dropdown.Toggle
                        className="nav-link i-false c-pointer"
                        variant=""
                        as="a"
                      >
                        <div onClick={() => onClickNotification()}>
                          <svg
                            width="24"
                            height="23"
                            viewBox="0 0 24 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M18.7071 8.56414C18.7071 9.74035 19.039 10.4336 19.7695 11.2325C20.3231 11.8211 20.5 12.5766 20.5 13.3963C20.5 14.215 20.2128 14.9923 19.6373 15.6233C18.884 16.3798 17.8215 16.8627 16.7372 16.9466C15.1659 17.0721 13.5937 17.1777 12.0005 17.1777C10.4063 17.1777 8.83505 17.1145 7.26375 16.9466C6.17846 16.8627 5.11602 16.3798 4.36367 15.6233C3.78822 14.9923 3.5 14.215 3.5 13.3963C3.5 12.5766 3.6779 11.8211 4.23049 11.2325C4.98384 10.4336 5.29392 9.74035 5.29392 8.56414V8.16515C5.29392 6.58996 5.71333 5.55995 6.577 4.55164C7.86106 3.08114 9.91935 2.19922 11.9558 2.19922H12.0452C14.1254 2.19922 16.2502 3.12359 17.5125 4.65728C18.3314 5.64484 18.7071 6.63146 18.7071 8.16515V8.56414ZM9.07367 19.1136C9.07367 18.642 9.53582 18.426 9.96318 18.3336C10.4631 18.2345 13.5093 18.2345 14.0092 18.3336C14.4366 18.426 14.8987 18.642 14.8987 19.1136C14.8738 19.5626 14.5926 19.9606 14.204 20.2134C13.7001 20.5813 13.1088 20.8143 12.4906 20.8982C12.1487 20.9397 11.8128 20.9407 11.4828 20.8982C10.8636 20.8143 10.2723 20.5813 9.76938 20.2125C9.37978 19.9606 9.09852 19.5626 9.07367 19.1136Z"
                              fill="#130F26"
                            />
                          </svg>
                          {isNotificationClicked == false &&
                            notificationCount > 0 && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "-5px", // Adjust top position as needed
                                  right: "-5px", // Adjust right position as needed
                                  background: "#ff3c26", // Adjust background color as needed
                                  color: "white", // Adjust text color as needed
                                  borderRadius: "50%",
                                  width: "20px",
                                  height: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "12px",
                                }}
                              >
                                {notificationCount}
                              </div>
                            )}
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        align="right"
                        className="mt-2 dropdown-menu dropdown-menu-end"
                      >
                        <PerfectScrollbar className="widget-media dlab-scroll p-3 height380">
                          {props.reduxCurrentState.one.readNotifications
                            .length == 0 &&
                            props.reduxCurrentState.one.unReadNotifications
                              .length == 0 && (
                              <h5
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "60vh",
                                }}
                                className="text-center"
                              >
                                No Notifications.
                              </h5>
                            )}

                          {props.reduxCurrentState.one.unReadNotifications
                            .length > 0 && (
                            <>
                              <p className="text-center border-bottom pb-2">
                                Unread (
                                {
                                  props.reduxCurrentState.one
                                    .unReadNotifications.length
                                }
                                )
                              </p>

                              <ul className="timeline">
                                {props.reduxCurrentState.one.unReadNotifications.map(
                                  (notification, index) => (
                                    // <Link key={index} to="/" >
                                    <li key={index}>
                                      <div className="timeline-panel">
                                        <div
                                          className={`media me-2 media-success`}
                                        >
                                          <i className="fa fa-home" />
                                        </div>
                                        <div className="media-body">
                                          <h6 className="mb-1">
                                            {" "}
                                            {notification.message}
                                          </h6>
                                          <small className="d-block">
                                            {notification.created_at}
                                          </small>
                                        </div>
                                      </div>
                                    </li>
                                    // </Link>
                                  )
                                )}
                              </ul>
                            </>
                          )}

                          <div>
                            <br></br>
                          </div>

                          {props.reduxCurrentState.one.readNotifications
                            .length > 0 && (
                            <>
                              <p className="text-center border-bottom pb-2">
                                Read
                              </p>

                              <ul className="timeline">
                                {props.reduxCurrentState.one.readNotifications.map(
                                  (notification, index) => (
                                    <li key={index}>
                                      <div className="timeline-panel">
                                        <div
                                          className={`media me-2 media-success`}
                                        >
                                          <i className="fa fa-home" />
                                        </div>
                                        <div className="media-body">
                                          <p className="mb-1">
                                            {" "}
                                            {notification.message}
                                          </p>
                                          <p className="d-block">
                                            {notification.created_at}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  )
                                )}
                              </ul>
                            </>
                          )}

                          <div
                            className="ps__rail-x"
                            style={{ left: 0, bottom: 0 }}
                          >
                            <div
                              className="ps__thumb-x"
                              tabIndex={0}
                              style={{ left: 0, width: 0 }}
                            />
                          </div>
                          <div
                            className="ps__rail-y"
                            style={{ top: 0, right: 0 }}
                          >
                            <div
                              className="ps__thumb-y"
                              tabIndex={0}
                              style={{ top: 0, height: 0 }}
                            />
                          </div>
                        </PerfectScrollbar>
                      </Dropdown.Menu>
                    </Dropdown>

                    {/* <Dropdown
                      as="li"
                      className="nav-item dropdown notification_dropdown "
                    >
                      <Dropdown.Toggle
                        variant=""
                        as="a"
                        className="nav-link  i-false c-pointer"
                        // onClick={() => onNote()}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16.9394 3.57129C18.2804 3.57129 19.5704 4.06765 20.5194 4.95828C21.4694 5.84704 22.0004 7.04579 22.0004 8.30073V15.6993C22.0004 18.3122 19.7304 20.4287 16.9394 20.4287H7.0604C4.2694 20.4287 2.0004 18.3122 2.0004 15.6993V8.30073C2.0004 5.68783 4.2594 3.57129 7.0604 3.57129H16.9394ZM18.5304 9.69615L18.6104 9.62123C18.8494 9.34964 18.8494 8.9563 18.5994 8.68471C18.4604 8.54517 18.2694 8.45994 18.0704 8.44121C17.8604 8.43091 17.6604 8.4974 17.5094 8.62852L13.0004 12C12.4204 12.4505 11.5894 12.4505 11.0004 12L6.5004 8.62852C6.1894 8.41312 5.7594 8.44121 5.5004 8.69407C5.2304 8.94693 5.2004 9.34964 5.4294 9.6306L5.5604 9.75234L10.1104 13.077C10.6704 13.4891 11.3494 13.7138 12.0604 13.7138C12.7694 13.7138 13.4604 13.4891 14.0194 13.077L18.5304 9.69615Z"
                            fill="#130F26"
                          />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        align="right"
                        className="mt-2 dropdown-menu dropdown-menu-end "
                      >
                        <div
                          style={{
                            display: "flex",
                            borderRadius: "10px",
                            // overflow: "hidden",
                          }}
                        >
                          <div
                            className={
                              activeTabEda === "Batch 1"
                                ? "btn btn-primary "
                                : "btn bg-dark"
                            }
                            style={{
                              padding: "10px 20px",
                              cursor: "pointer",
                              // backgroundColor: activeTab === 1 ? "#e83e8c" : "#fff",
                              color: "#fff",
                              borderRadius: "20px 20px 20px 20px",
                              transition: "background-color 0.3s ease",
                              marginRight: "10px",
                            }}
                            // onClick={() => handleTabEdaClick("Batch 1")}
                          >
                            My Friends
                          </div>
                          <div
                            className={
                              activeTabEda === "Batch 2"
                                ? "btn btn-primary "
                                : "btn bg-dark"
                            }
                            style={{
                              padding: "10px 20px",
                              cursor: "pointer",
                              // backgroundColor: activeTab === 2 ? "#3498db" : "#fff",
                              color: "#fff",
                              borderRadius: "20px 20px 20px 20px",
                              transition: "background-color 0.3s ease",
                              marginRight: "10px",
                            }}
                            // onClick={() => handleTabEdaClick("Batch 2")}
                          >
                            New Requests
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown> */}

                    
                  </ul>
                </div>

                <ul>

                  
                  <Dropdown
                    as="li"
                    className="nav-item dropdown header-profile"
                  >
                    <Dropdown.Toggle
                      variant=""
                      as="a"
                      className="nav-link i-false c-pointer"
                    >
                      <img
                        src={props.reduxCurrentState.one.profileURL}
                        width={40}
                        alt=""
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      align="right"
                      className="dropdown-menu dropdown-menu-end"
                    >
                      <Link to="/my-account" className="dropdown-item ai-icon">
                        <svg
                          id="icon-user1"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-primary me-1"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                        <span className="ms-2">My Account </span>
                      </Link>
                      <Link
                        to="/change-password"
                        className="dropdown-item ai-icon"
                      >
                        <svg
                          id="icon-inbox"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-success me-1"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                          <polyline points="22,6 12,13 2,6" />
                        </svg>
                        <span className="ms-2">Change Password </span>
                      </Link>
                      <LogoutPage />
                    </Dropdown.Menu>
                  </Dropdown>
                </ul>
              </div>
            </div>
          
          </div>
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(Header);
