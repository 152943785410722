import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

// import saveAndSubmitLeadAction from "../../../store/actions/OMS/Actions";

import {saveAndSubmitLeadAction} from "../../../store/actions/OMS/Actions";

import { ThemeContext } from "../../../context/ThemeContext";

const AddNewLead = (props) => {
  const [data, setData] = useState(
    document.querySelectorAll(".email-right-box .email-list .message")
  );
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [image, setImage] = useState(
    props.reduxCurrentState.one.myAccount.image
  );

  const reduxUsername = useSelector((state) => state.one.myAccount.username);
  const [isEditUsername, setIsEditUsername] = useState(false);
  const [localUsername, setLocalUsername] = useState(reduxUsername);

  const [isLoading, setIsLoading] = useState(false);

  const [fullName, setFullname] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [gender, setGender] = useState("Male");

  const [state, setState] = useState("Education Completed");

  const [yop, setYop] = useState("2024");
  const [mainDegree, setMainDegree] = useState("B.Tech");
  const [specialization, setSpecialization] = useState("Computer Science");

  const [technologyInterested, setTechnologyInterested] = useState(
    "Data Science & AI"
  );
  const [currentKnowledge, setCurrentKnowledge] = useState("LKG");
  const [learningMode, setLearningMode] = useState("Offline");
  const [learningGoal, setLearningGoal] = useState("Getting a Job");
  const [leadSource, setLeadSource] = useState("Unknown");
  const [nextAction, setNextAction] = useState("Need to Send Content");

  const [upiID, setUpiID] = useState(
    props.reduxCurrentState.one.myAccount.upi_id
  );

  const [isEdited, setIsEdited] = useState(false);

  const dispatch = useDispatch();

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
    changeNavigationHader(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
  }, [darkModeData]);

  useEffect(() => {
    // dispatch(getMyAccountDetails(props.reduxCurrentState.one.webAuthTokenId));
    setLocalUsername(reduxUsername);
  }, [reduxUsername]);

  const handleFullnameChange = (e) => {
    setFullname(e.target.value);
  };

  const handleWhatsappNoChange = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/[^0-9]/g, "");

    if (numericValue.length <= 10) {
      setWhatsappNo(numericValue);
    }
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleGender = (event) => {
    setGender(event.target.value);
  };

  const handleState = (event) => {
    setState(event.target.value);
  };

  const handleYOP = (event) => {
    setYop(event.target.value);
  };

  const handleMainDegree = (event) => {
    setMainDegree(event.target.value);
  };

  const handleSpecialization = (event) => {
    setSpecialization(event.target.value);
  };

  const handleTechnologyInterested = (event) => {
    setTechnologyInterested(event.target.value);
  };

  const handleCurrentKnowledge = (event) => {
    setCurrentKnowledge(event.target.value);
  };

  const handleLearningMode = (event) => {
    setLearningMode(event.target.value);
  };

  const handleLearningGoal = (event) => {
    setLearningGoal(event.target.value);
  };

  const handleLeadSource = (event) => {
    setLeadSource(event.target.value);
  };

  const handleNextAction = (event) => {
    setNextAction(event.target.value);
  };

  const onPressSaveAndSubmit = (temp) => {
    let message = null;

    setIsLoading(true);

    if (!fullName) {
      message = "Please enter valid name";
    } else if (!whatsappNo | (whatsappNo.length != 10)) {
      message = "Please enter valid whatsapp number";
    }

    if (message !== null) {
      swal("Oops", message, "error");
      setIsLoading(false); // Stop loading if there's an error
      return;
    }

    const data = {
      fullName: fullName,
      whatsappNo: whatsappNo,
      email: email,
      gender: gender,
      state: state,
      yop: yop,
      mainDegree: mainDegree,
      specialization: specialization,
      technologyInterested: technologyInterested,
      currentKnowledge: currentKnowledge,
      learningMode: learningMode,
      learningGoal: learningGoal,
      leadSource: leadSource,
      nextAction:nextAction,
      temp: temp,
    };

    dispatch(
      saveAndSubmitLeadAction(props.reduxCurrentState.one.webAuthTokenId, data)
    )
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        swal("Error", "Something went wrong. Please try again later.", "error");
        setIsLoading(false);
      });
  };

  return (
    <Fragment>
      <div className="row">
        <div className="login-wrapper ">
          <div className="container h-100 ">
            <div className="row h-100 d-flex justify-content-center align-items-center">
              <div className="col-xl-0 col-md-8">
                <div className="sign-in-your">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row">
                        <div style={{ textAlign: "center" }}>
                          <label
                            style={{ fontSize: "15px", fontWeight: "bold" }}
                          >
                            Basic Details
                          </label>
                          <hr
                            style={{
                              border: "0.5px solid black",
                              width: "100%",
                            }}
                          />
                        </div>

                        <div>
                          <br></br>
                        </div>

                        <div className="form-group mb-3 col-md-6">
                          <label>
                            Full Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={fullName}
                            placeholder="Please enter name"
                            onChange={handleFullnameChange}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                          />
                        </div>

                        <div className="form-group mb-3 col-md-6">
                          <label>
                            Whatsapp No <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={whatsappNo}
                            onChange={handleWhatsappNoChange}
                            placeholder="Please enter whatsapp no"
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                            length={10}
                          />
                        </div>

                        <div className="form-group mb-3 col-md-6">
                          <label>Email </label>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Please enter email"
                            value={email}
                            onChange={handleEmailChange}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                          />
                        </div>

                        <div className="form-group mb-4 col-md-6">
                          <label>Gender</label>
                          <select
                            id="inputState"
                            className="form-control"
                            value={gender}
                            onChange={handleGender}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                          >
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Unknown">Unknown</option>
                          </select>
                        </div>

                        <div>
                          <br></br>
                        </div>

                        <div style={{ textAlign: "center" }}>
                          <label
                            style={{ fontSize: "15px", fontWeight: "bold" }}
                          >
                            Education Details
                          </label>
                          <hr
                            style={{
                              border: "0.5px solid black",
                              width: "100%",
                            }}
                          />
                        </div>

                        <div>
                          <br></br>
                        </div>

                        <div className="row">
                          <div className="form-group mb-4 col-md-6">
                            <label>Current Status</label>
                            <select
                              id="inputState"
                              className="form-control"
                              value={state}
                              onChange={handleState}
                              style={{
                                backgroundColor: "white",
                                color: "black",
                              }}
                            >
                              <option value="Education Completed">
                                Education Completed{" "}
                              </option>
                              <option value="Currently Pursuing">
                                Currently Pursuing{" "}
                              </option>
                              <option value="Currently Working">
                                Currently Working{" "}
                              </option>

                              <option value="Freelancing/Business">
                                Freelancing/Business{" "}
                              </option>
                              <option value="Unknown">Unknown</option>
                            </select>
                          </div>

                          <div className="form-group mb-4 col-md-6">
                            <label>Year of Passing</label>
                            <select
                              id="passoutYear"
                              className="form-control"
                              value={yop}
                              onChange={handleYOP}
                              style={{
                                backgroundColor: "white",
                                color: "black",
                              }}
                            >
                              <option value="" disabled selected>
                                Select Year
                              </option>
                              {Array.from(
                                { length: 14 },
                                (_, i) => 2014 + i
                              ).map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                              <option value="Unknown">Unknown</option>
                            </select>
                          </div>

                          <div className="form-group mb-4 col-md-6">
                            <label>Main Degree</label>
                            <select
                              id="degree"
                              className="form-control"
                              value={mainDegree}
                              onChange={handleMainDegree}
                              style={{
                                backgroundColor: "white",
                                color: "black",
                              }}
                            >
                              <option value="B.Tech">
                                B.Tech (Bachelor of Technology)
                              </option>
                              <option value="B.E.">
                                B.E. (Bachelor of Engineering)
                              </option>
                              <option value="B.Sc">
                                B.Sc (Bachelor of Science)
                              </option>
                              <option value="BCA">
                                BCA (Bachelor of Computer Applications)
                              </option>
                              <option value="B.Com">
                                B.Com (Bachelor of Commerce)
                              </option>
                              <option value="BBA">
                                BBA (Bachelor of Business Administration)
                              </option>
                              <option value="B.Voc">
                                B.Voc (Bachelor of Vocation)
                              </option>
                              <option value="M.Tech">
                                M.Tech (Master of Technology)
                              </option>
                              <option value="M.E.">
                                M.E. (Master of Engineering)
                              </option>
                              <option value="M.Sc">
                                M.Sc (Master of Science)
                              </option>
                              <option value="MCA">
                                MCA (Master of Computer Applications)
                              </option>
                              <option value="MBA">
                                MBA (Master of Business Administration)
                              </option>
                              <option value="PG">PG</option>
                              <option value="PhD">
                                PhD (Doctor of Philosophy)
                              </option>
                              <option value="Certification">
                                Professional Certification
                              </option>
                              <option value="High School">
                                High School (10th Pass)
                              </option>
                              <option value="Intermediate">
                                Intermediate (12th Pass)
                              </option>
                              <option value="Diploma">Diploma</option>

                              <option value="Other">Other</option>
                              <option value="Unknown">Unknown</option>
                            </select>
                          </div>

                          <div className="form-group mb-4 col-md-6">
                            <label>Specialization</label>
                            <select
                              id="specialization"
                              className="form-control"
                              value={specialization}
                              onChange={handleSpecialization}
                              style={{
                                backgroundColor: "white",
                                color: "black",
                              }}
                            >
                              <option value="Computer Science">
                                Computer Science
                              </option>
                              <option value="CS-AI/ML">CS-AI/ML</option>

                              <option value="Information Technology">
                                Information Technology
                              </option>
                              <option value="Electrical Engineering">
                                Electrical Engineering
                              </option>
                              <option value="Electronics and Communication">
                                Electronics and Communication
                              </option>
                              <option value="Mechanical Engineering">
                                Mechanical Engineering
                              </option>
                              <option value="Civil Engineering">
                                Civil Engineering
                              </option>
                              <option value="Chemical Engineering">
                                Chemical Engineering
                              </option>
                              <option value="Aeronautical Engineering">
                                Aeronautical Engineering
                              </option>
                              <option value="Automobile Engineering">
                                Automobile Engineering
                              </option>
                              <option value="Commerce">Commerce</option>
                              <option value="Business Administration">
                                Business Administration
                              </option>
                              <option value="Software Development">
                                Software Development
                              </option>
                              <option value="Data Science">Data Science</option>
                              <option value="Marketing">Marketing</option>
                              <option value="Finance">Finance</option>
                              <option value="Operations Management">
                                Operations Management
                              </option>
                              <option value="Human Resources">
                                Human Resources
                              </option>

                              <option value="Other">Other</option>
                              <option value="Unknown">Unknown</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="login-wrapper ">
          <div className="container h-100 ">
            <div className="row h-100 d-flex justify-content-center align-items-center">
              <div className="col-xl-0 col-md-8">
                <div className="sign-in-your">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row">
                        <div style={{ textAlign: "center" }}>
                          <label
                            style={{ fontSize: "15px", fontWeight: "bold" }}
                          >
                            Course Details
                          </label>
                          <hr
                            style={{
                              border: "0.5px solid black",
                              width: "100%",
                            }}
                          />
                          <br></br>
                        </div>

                        <div className="form-group mb-4 col-md-6">
                          <label>Technology Interested</label>
                          <select
                            id="inputState"
                            className="form-control"
                            value={technologyInterested}
                            onChange={handleTechnologyInterested}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                          >
                            <option value="Data Science & AI">
                              Data Science & AI
                            </option>
                            <option value="Data Analytics">
                              Data Analytics
                            </option>
                            <option value="Generative AI">Generative AI</option>
                            <option value="Digital Marketing">
                              Digital Marketing
                            </option>
                            <option value="Cyber Security">
                              Cyber Security
                            </option>
                            <option value="Python Full Stack">
                              Python Full Stack
                            </option>
                            <option value="Soft Skills">Soft Skills</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>

                        <div className="form-group mb-4 col-md-6">
                          <label>Current Knowledge</label>

                          <select
                            id="passoutYear"
                            className="form-control"
                            value={currentKnowledge}
                            onChange={handleCurrentKnowledge}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                          >
                            <option value="LKG">LKG</option>
                            <option value="Basic">Basic</option>
                            <option value="Somewhat">Somewhat</option>
                            <option value="Intermediate">Intermediate</option>
                            <option value="Other">Advanced</option>
                          </select>
                        </div>

                        <div className="form-group mb-4 col-md-6">
                          <label>Learning Mode</label>
                          <select
                            id="specialization"
                            className="form-control"
                            value={learningMode}
                            onChange={handleLearningMode}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                          >
                            <option value="Offline">Offline</option>
                            <option value="Online">Online</option>

                            <option value="Unknown">Unknown</option>
                          </select>
                        </div>

                        <div className="form-group mb-4 col-md-6">
                          <label>Learning Goal</label>
                          <select
                            id="degree"
                            className="form-control"
                            value={learningGoal}
                            onChange={handleLearningGoal}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                          >
                            <option value="Getting a Job">Getting a Job</option>
                            <option value="Study Abroad">Study Abroad</option>
                            <option value="Just Upskilling">
                              Just Upskilling
                            </option>

                            <option value="For his/her Business">
                              For his/her Business{" "}
                            </option>

                            <option value="Other">Other</option>
                            <option value="Unknown">Unknown</option>
                          </select>
                        </div>

                        <div className="form-group mb-4 col-md-6">
                          <label>Lead Source</label>
                          <select
                            id="specialization"
                            className="form-control"
                            value={leadSource}
                            onChange={handleLeadSource}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                          >
                            <option value="Local Marketing Guy">
                              Local Marketing Guy
                            </option>
                            <option value="Outside Board">Outside Board</option>
                            <option value="Hostel Poster">Hostel Poster</option>

                            <option value="Social Media">Social Media</option>
                            <option value="Internet">Internet</option>
                            <option value="Referral">Referral</option>
                            <option value="PhoneCall">PhoneCall</option>
                            <option value="Whatsapp">Whatsapp</option>

                            <option value="Unknown">Unknown</option>
                          </select>
                        </div>

                        <div className="form-group mb-4 col-md-6">
                          <label>Next Action</label>

                          <select
                            id="degree"
                            className="form-control"
                            value={nextAction}
                            onChange={handleNextAction}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                          >
                            <option value="Need to Send Content">Need to Send Content</option>
                            <option value="Wait for their response">
                              Wait for their response
                            </option>
                            <option value="Need Demo">Need Demo</option>

                            <option value="Other">Other</option>
                            <option value="Unknown">Unknown</option>
                          </select>

                        </div>
                      </div>

                      {isLoading ? (
                        <div className="text-center">
                          <div
                            className="spinner-border text-primary text-center"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="form-group mb-3 col-md-6">
                            <label>.</label>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-primary btn-block"
                                onClick={() => onPressSaveAndSubmit(true)}
                              >
                                Save & Cancel
                              </button>
                            </div>
                          </div>

                          <div className="form-group mb-3 col-md-6">
                            <label>.</label>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-secondary btn-block"
                                onClick={() => onPressSaveAndSubmit(false)}
                              >
                                Save & Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(AddNewLead);
