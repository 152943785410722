import React from "react";
import ReactApexChart from "react-apexcharts";

class TransactionsEarningCourses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: props.data?.map(item => item.y) || [],
      options: {
        chart: {
          type: "donut",
          height: 250,
        },
        labels: props.categories || [],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 0,
          curve: "smooth",
          lineCap: "round",
        },
        colors: props.data?.map(item => item.fillColor) || [],
        legend: {
          position: "bottom",
          show: false,
        },
        tooltip: {
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
            return `<div style="padding: 10px; background-color: #fff; color: #000; border-radius: 5px; font-size: 14px; font-family: 'Poppins', sans-serif;">
                      <span>${w.config.labels[seriesIndex]}: ${series[seriesIndex]}</span>`;
          }
        },
        responsive: [
          {
            breakpoint: 1800,
            options: {
              chart: {
                height: 200,
              },
            },
          },
          {
            breakpoint: 1800,
            options: {
              chart: {
                height: 200,
              },
            },
          },
        ],
      },
    };
  }

  componentDidUpdate(prevProps) {
    // Check if the props actually changed before setting state
    if (
      prevProps.data !== this.props.data ||
      prevProps.categories !== this.props.categories
    ) {
      const newSeries = this.props.data.map(item => item.y);
      const newColors = this.props.data.map(item => item.fillColor);

      // Only update the state if the new data is different
      if (
        newSeries.join(',') !== this.state.series.join(',') ||
        newColors.join(',') !== this.state.options.colors.join(',')
      ) {
        this.setState({
          series: newSeries,
          options: {
            ...this.state.options,
            labels: this.props.categories,
            colors: newColors,
          },
        });
      }
    }
  }

  render() {
    return (
      <div id="emailchart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          height={250}
        />
      </div>
    );
  }
}

export default TransactionsEarningCourses;
